<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item :to="disableHome ? null : '/'">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in filteredBreadcrumbs"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
              <feather-icon v-if="!!item.icon" :icon="item.icon"/>
              <svg v-if="!!item.svgIcon" :width="item.svgIcon.size || `24`" :height="item.svgIcon.size || `24`" :viewBox="`0 0 24 24`" class="align-text-top">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="item.svgIcon.icon"/>
              </svg>
              <span v-if="!!item.text">{{ getTitleBreadcrum(item) }}</span>
              <b-badge v-if="item.beta" class="ml-05" variant="warning">BETA</b-badge>
              <span v-if="!!item.detail">
                {{ $route.params[item.detail] }}
              </span>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  props: ['disableHome'],
  directives: {
    Ripple
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol
  },
  computed: {
    userRole() {
      return JSON.parse(localStorage.getItem('userData'))?.role
    },
    filteredBreadcrumbs() {
      return this.$route.meta.breadcrumb.filter(item => {
        if (!item.showOnRoles) return true
        return item.showOnRoles.includes(this.userRole)
      })
    }
  },
  methods: {
    getTitleBreadcrum (item) {
      let text = item.text
      if (this.$route.params?.breadcrumb) {
        Object.keys(this.$route.params.breadcrumb).map(key => {
          text = text.replace(`{${key}}`, this.$route.params.breadcrumb[key])
        })
      } else if (this.$route.params) {
        Object.keys(this.$route.params).map(key => {
          text = text.replace(`{${key}}`, this.$route.params[key])
        })
      }
      return text
    }
  }
}
</script>

<style>
  .breadcrumb-wrapper {
    min-height: 29px;
  }
</style>